@import url('https://fonts.googleapis.com/css?family=Numans');
/** Generated by FG **/
@font-face {
	font-family: 'Conv_HelveticaNeue';
	src: url('assets/fonts/HelveticaNeue.eot');
	src: local('☺'), url('assets/fonts/HelveticaNeue.eot') format('woff'), url('assets/fonts/HelveticaNeue.ttf') format('truetype'), url('assets/fonts/HelveticaNeue.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_HelveticaNeueBd';
	src: url('assets/fonts/HelveticaNeueBd.eot');
	src:  url('assets/fonts/HelveticaNeueBd.woff') format('woff'), url('assets/fonts/HelveticaNeueBd.ttf') format('truetype'), url('assets/fonts/HelveticaNeueBd.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_HelveticaNeueBd';
	src: url('assets/fonts/HelveticaNeueBd.eot');
	src:  url('assets/fonts/HelveticaNeueBd.woff') format('woff'), url('assets/fonts/HelveticaNeueBd.ttf') format('truetype'), url('assets/fonts/HelveticaNeueBd.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: Jenna Sue;
  src: url('assets/fonts/JENNASUE_0.TTF'); 
  /* src: url('assets/fonts/JENNASUE_0.eot');
	src:  url('assets/fonts/JENNASUE_0.woff') format('woff'), url('assets/fonts/JENNASUE_0.ttf') format('truetype'), url('assets/fonts/JENNASUE_0.svg') format('svg'); */
  /* src: url('../fonts/JennaSue-webfont.eot');
  src: url('../fonts/JennaSue-webfont.ttf'); 
  src: url('../fonts/JennaSue-webfont.woff'); */
  /* src: local("Jenna Sue"), url('../fonts/JennaSue-webfont.ttf'); */
  }
html,body{

background-size: cover;
background-repeat: no-repeat;
height: 100%;
font-family: 'Numans', sans-serif;color: #4d555b
}
.home_text {
  FONT-SIZE: 46PX;
  COLOR: #FFF;
  FONT-WEIGHT: BOLD;
  Z-INDEX: 11111111111;
  margin-top: 65px;
  margin-left: 86px;
  font-family: 'Raleway',sans-serif;
  position: absolute;
}
.customers_login {
 
 -webkit-background-size: cover;
 -moz-background-size: cover;
  background-size: cover;
  height: 100%;
  }

.container{
height: 100%;
align-content: center;
}
/*--------------------------------------------------------------------------
Login
--------------------------------------------------------------------------------
*/
.login-box { font-size: 14px; color: #4d555b}
.login-box a { color: #4d555b}
.login-box .card{

margin-top: auto;
margin-bottom: auto;
width: 289px; background: #ffffff; border-radius:10px; box-shadow: 0 0 5px #000;
justify-content: flex-end;
height:408px
}

.login-box .avatar {
    color: #fff;
    margin: 26px auto 46px;
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    z-index: 9;
     padding:0 0px;
     background: #fff;
    border: 1px solid #e2e2e2;
    text-align:center;
    padding: 5px;
}
.login-box .avatar img {
    position: relative;
    top: 11px;}
.login-box label { margin-bottom:0; font-size: 12px; line-height: 24px; padding-left:22px}
.login-box .form-control { 
    border: 0; font-size: 12px; 
    color:#4d555b;
    padding: 0 5px;margin-left: -18px; border:1px solid #f3f3f3; height:30px;

}
.login-box .form-group { margin-bottom: 0.5rem}




.text-dark {color:  #4d555b!important}

.login-box .divider { border-top:1px solid #e2e2e2 }

.login-box  .grey-button{ 
 background: #00aae2;
 border-radius: 40px;
 color: #fff; border: none ; padding:8px 60px; }
 .btn-secondary:hover {
  color: #fff;
  background-color: #0291c0 !important;
  border-color: #545b62;
}
 .login-box input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}
/*------------------------------------------------------------------------------
inner page
--------------------------------------------------------------------------------*/

.hr-border { border-bottom: 1px solid #b0b0b0}
.hr-top-border { border-top: 1px solid #b0b0b0}
.small-title { color: #4d555b; font-size: 15px; font-weight: bold; margin-bottom: 15px;font-family: 'Conv_HelveticaNeueBd';}

/* Base for label styling */
.customize-checkbox [type="checkbox"]:not(:checked),
.customize-checkbox [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.customize-checkbox [type="checkbox"]:not(:checked) +  label,
.customize-checkbox [type="checkbox"]:checked +  label {
  position: relative;
  /*padding-left: 1.95em;*/
  padding-left: 19px;
  cursor: pointer; font-weight: 600; font-size: 11px
}

/* checkbox aspect */
.customize-checkbox [type="checkbox"]:not(:checked) + label:before,
.customize-checkbox [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.45em; height: 1.40em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 0px; 
  
}
/* checked mark aspect */
.customize-checkbox [type="checkbox"]:not(:checked) +  label:after,
.customize-checkbox [type="checkbox"]:checked +  label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #3e4f61;
  transition: all .2s;
  font-family: Arial; font-weight: bold
}
/* checked mark aspect changes */
.customize-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.customize-checkbox [type="checkbox"]:checked +  label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.customize-checkbox [type="checkbox"]:disabled:not(:checked) +  label:before,
.customize-checkbox [type="checkbox"]:disabled:checked +  label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.customize-checkbox [type="checkbox"]:disabled:checked +  label:after {
  color: #999;
}
.customize-checkbox [type="checkbox"]:disabled +  label {
  color: #aaa;
}
/* accessibility */
.customize-checkbox [type="checkbox"]:checked:focus +  label:before,
.customize-checkbox [type="checkbox"]:not(:checked):focus +  label:before {
    border: 1px solid #ccc!important;
}

/* hover style just for information */
.customize-checkbox label:hover:before {
  border: 1px solid #ccc!important;
}
.customize-checkbox label {margin-bottom: 0}
/*----------------------------------------------------------------
 commen css
 ----------------------------------------------------------------*/
 .zero-padding {  padding-right:0!important}
 .header-position { position: relative}
 .sign-out { position: absolute; right:0px; top:-4px}
 a, a:hover { text-decoration: none!important}
 .text12 { font-size: 12px; line-height: 22px; font-weight: bold}
/*--------------------------------------------------------------
video
---------------------------------------------------------------*/

.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
#vidtop-content {
	top: 0;
	color: #fff;
}
.vid-info { position: absolute; top: 0; right: 0; width: 33%; background: rgba(0,0,0,0.3); color: #fff; padding: 1rem; font-family: Avenir, Helvetica, sans-serif; }
.vid-info h1 { font-size: 2rem; font-weight: 700; margin-top: 0; line-height: 1.2; }
.vid-info a { display: block; color: #fff; text-decoration: none; background: rgba(0,0,0,0.5); transition: .6s background; border-bottom: none; margin: 1rem auto; text-align: center; }
@media (min-aspect-ratio: 16/9) {
  .video-foreground { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground { width: 300%; left: -100%; }
}
@media all and (max-width: 600px) {
.vid-info { width: 50%; padding: .5rem; }
.vid-info h1 { margin-bottom: .2rem; }
}
@media all and (max-width: 500px) {
.vid-info .acronym { display: none; }
}

/*----------------------------------------------------------------
Services Box
-----------------------------------------------------------------*/

.services-box .bg-light { border-radius: 8px; box-shadow:0 0 10px #d5d5d5; background-color: #fff!important; margin-bottom: 10px}
.services-box .bg-light .card-body  {padding: 8px; text-align: center; height:146px; overflow: hidden;}
.services-box .bg-light .card-body img {border-radius: 8px; }
.services-box .card-footer { padding:6px 8px; border: none;padding-right:15px; }
.services-box .card-footer img { padding: 1px 0px;
  position: absolute;}
.services-box .card-footer i { font-size: 25px; color:#fff;  filter: drop-shadow(0px 0px 1px rgba(0,0,0)); margin-left:-8px;padding-right:15px; }
.services-box h5 { font-weight: bold; font-size: 11px; color: #4d555b;font-family: 'Conv_HelveticaNeueBd';}
.services-box p {  font-size: 14px; color: #4d555b}

.green-color { background: #98bd5e !important}
.blue-color { background: #7e87e4 !important}
.yellow-color { background: #ebc058 !important}
.lightblue-color { background: #5fd0de !important}
.voilet-color { background: #bf6ec4 !important}
.pink-color { background: #ff9dc2 !important}
.lightyellow-color { background: #ebef81 !important}
.skyblue-color { background: #7dbde5 !important}
.lightgreen-color { background: #98bd5e !important}


/*----------------------------------------------------------------
footer
-----------------------------------------------------------------*/
.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}
.footer { padding: 10px; text-align: center; width:100%; display: block; color: #4d555b; text-align:center;font-size:11px}

.errormsg{
    display: block;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}
@media (max-width:1170px){
  .services-box .bg-light .card-body { height: auto;}
  .ml10 {margin-left:10px}

}
@media (max-width:680px){
  .sign-out { position: static;}
  .login-box .form-control { 
   
    padding: 0;margin-left: -0px;

}
.login-box .avatar {
  margin: 16px auto 26px;
}
}